import React, { useState, useEffect } from "react";
import LeaveCheckout from "./LeaveCheckout";
import { Link } from "react-router-dom";
import { ClipLoader } from 'react-spinners';
import calendarVector from "../../../Asset/Events/calendar-vector.svg";
import locationVector from "../../../Asset/Events/location-vector.svg";
import timeVector from "../../../Asset/Events/time-vector.svg";
import { format } from "date-fns";
import axios from "axios";
import { useParams } from "react-router-dom";
import './CheckoutModal.css'


const CheckoutModal = ({ isOpen, closeModal, count, eventData, addToWaitlist, alreadyOnWaitlist, havePartnerFlag }) => {
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [formattedDates, setFormattedDates] = useState({});
  let { eventId } = useParams();
  const [errors, setErrors] = useState({});
  const [teamErrors, setTeamErrors] = useState([]);
  const [checkoutError, setCheckoutError] = useState(false)
  const [loading, setLoading] = useState(false);
  const [isEventFull, setIsEventFull] = useState(false);
  const [isAddedToWaitlist, setIsAddedToWaitlist] = useState(false);
  const [isMember, setIsMember] = useState(false)

  //useState for multiple registration from one number
  const [duplicateRegistration, setDuplicateRegistration] = useState(false);
  const [sameNumberEntered, setSameNumberEntered] = useState(false);





  const [formData, setFormData] = useState({
    Email: "",
    Phone: "",
    ext: "",
    Gender: "", // Default to "Man"
    FirstName: "",
    LastName: "",
    org_id: "",
    role: "General", // Default role to "General"
    agreeTerms: false,
    subscribeUpdates: false
  });

  // State for team members
  const [teamMembers, setTeamMembers] = useState(
    Array.from({ length: count }, () => ({
      FirstName: '',
      LastName: '',
      Phone: '',

      Email: null,

      Gender: '' // Initialize with empty string
    }))
  );

  // State to store user ID from the signup response
  const [userId, setUserId] = useState(null);
  const [orderId, setOrderId] = useState(null);

  // Check if all input fields are filled and checkbox is checked
  const isFormValid =
    formData.FirstName.trim() !== '' &&
    formData.LastName.trim() !== '' &&
    formData.Email.trim() !== '' &&
    formData.Phone.trim() !== '' &&
    formData.Gender !== '' &&
    formData.agreeTerms && // Validate the single agreeTerms checkbox
    teamMembers.every(
      (member) =>
        member.FirstName.trim() !== '' &&
        member.LastName.trim() !== '' &&
        member.Phone.trim() !== '' &&
        member.Gender !== ''
    );

  // Handle input change for signup form
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    // setFormData((prevData) => ({
    //   ...prevData,
    //   [name]: value,
    // }));

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });

    const updatedTeamMembers = [...teamMembers];
    updatedTeamMembers[0][name] = value;
    setTeamMembers(updatedTeamMembers);
  };

  // Handle input change for individual fields
  const handleTeamInputChange = (index, field, value) => {
    const updatedTeamMembers = [...teamMembers];
    updatedTeamMembers[index][field] = value;
    setTeamMembers(updatedTeamMembers);
    // validateFields(field);
  };

  //validating form fields
  const validateFields = () => {
    const newErrors = {};
    if (!formData.FirstName) newErrors.FirstName = true;
    if (!formData.LastName) newErrors.LastName = true;
    if (!validateEmail(formData.Email)) newErrors.Email = true;
    if (!validateMobile(formData.Phone)) newErrors.Phone = true;
    if (!formData.Gender) newErrors.Gender = true;
    if (!formData.agreeTerms) newErrors.agreeTerms = true;

    const teamValid = validateTeamMembers();

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateTeamMembers = () => {
    const newErrors = teamMembers.map((member) => {
      let memberErrors = {};
      if (!member.FirstName) memberErrors.FirstName = true;
      if (!member.LastName) memberErrors.LastName = true;
      if (!validateMobile(member.Phone)) memberErrors.Phone = true;
      if (!member.Gender) memberErrors.Gender = true;

      return memberErrors;
    });

    setTeamErrors((prevErrors) => ({ ...prevErrors, teamMembers: newErrors }));

    // Check if there are no errors
    return newErrors.every((error) => Object.keys(error).length === 0);
  };

  const validateEmail = (Email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(Email);

  };

  const validateMobile = (Phone) => {
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(Phone);
  };

  // Function to handle form submission for signup, orders and checkout endpoints
  const handleSubmit = async (e) => {
    setCheckoutError(false)
    e.preventDefault();
    setDuplicateRegistration(false);
    setSameNumberEntered(false)

    if (!validateFields()) {

      return;
    }

    setLoading(true); // Show loader

    try {


      // Check if the user already exists using the /users/profile/{MobileNumber} endpoint
      let id; // Initialize the variable to store user ID
      try {
        const profileResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/users/profile/${formData.Phone}`,
          {
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
          }
        );

        // If the user exists, get the user ID
        const { UserId } = profileResponse.data; // Destructure UserId from the response
        id = UserId; // Assign the user ID to the variable

      } catch (error) {
        if (error.response && (error.response.status === 500 || error.response.status === 404)) {

          let signupResponse;
          try {
            signupResponse = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/signup/`,
              {
                email: formData.Email,
                mobile: formData.Phone,
                first_name: formData.FirstName,
                last_name: formData.LastName,
                org_id: formData.org_id || "", // Ensure org_id is included or defaults to empty string
                role: formData.role,
                ext: formData.ext || "+91", // Optional
                gender: formData.Gender,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  accept: "application/json",
                },
              }
            );

            // Extract and store ID from the /signup response
            id = signupResponse.data.id; // Assign the user ID directly
            setUserId(id);
          } catch (error) {
            if (error.response && error.response.status == 409) {
              setDuplicateRegistration(true);
            }
          }

        } else {
          console.error("Error during user profile check:", error);
          throw error; // Re-throw error to exit the process
        }
      }

      // Now, make the POST request to the /orders endpoint using the userId
      let orderResponse;
      let SubOrderId
      let WaitlistId
      try {
        orderResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/events/orders/`,
          {
            EventId: eventId,
            primaryUserId: id, // Use the ID returned from the signup response
            OrderData: {
              Team: teamMembers
            },
            "HasPartner": havePartnerFlag,
            "IsUsingCredits": false,
            "PutOnWaitlist": eventData.isFull || false,
            "Split": false,
            WaitlistOrderId: null, // Optional, can be left blank if not provided
          },
          {
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
          }
        );


        SubOrderId = orderResponse.data.SubOrderId
        WaitlistId = orderResponse.data.WaitlistId

        if (SubOrderId) {
          setOrderId(SubOrderId);
        } else if (WaitlistId) {
          addToWaitlist(true);
          alreadyOnWaitlist(false);
          closeModal();
        }



      } catch (error) {
        const errorMessage = error.response.data.detail;
        console.error(errorMessage);
        if (error.response && error.response.status === 409) {

          if (errorMessage.includes('waitlist')) {
            addToWaitlist(false);
            alreadyOnWaitlist(true);
            closeModal()
          }
          else if (errorMessage.includes('User')) {
            setDuplicateRegistration(true);
          }
          else if (errorMessage.includes('same number')) {
            setSameNumberEntered(true)
          }
        }
        console.error("Error during order creation:", error);
        return
      }


      // Checkout Endpoint code here
      // Finally, make the POST request to the /checkout endpoint using the generated OrderId

      setIsMember(false)
      let checkoutResponse
      try {
        checkoutResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/checkout/?sub_order_id=${SubOrderId}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
          }
        );


      } catch (error) {

        if (error.response && error.response.status === 403) {
          setIsMember(true)
        }
        else if (error.response && error.response.status === 409) {
          // setIsEventFull(true)
          // setIsAddedToWaitlist(false)

          //data to be sent to parent (current event)
          addToWaitlist(true);
          alreadyOnWaitlist(false);

          // isAddedToWaitlist is null so checking if user is already on waitlist
          if (error.response.data.detail.order_id?.length > 0) {



            //data to be sent to parent (current event)
            addToWaitlist(false);
            alreadyOnWaitlist(true);
            //  closeModal()

          } else {

          }
          closeModal();
        }


        console.error(error);
        setCheckoutError(true)

      }


      //storing data to proceed with razorpay
      const { RazorpayOrderId, Amount } = checkoutResponse.data;


      //Call Razorpay payment handling function
      handleRazorpayPayment(RazorpayOrderId, formData, Amount);
    } catch (error) {



    } finally {
      setLoading(false); // Hide loader
    }
  };

  console.log("raz id", process.env.REACT_APP_RAZORPAY_ID)

  const handleRazorpayPayment = async (RazorpayOrderId, formData, Amount) => {
    let amount
    amount = Amount * 100 * count;

    //Dynamically create a form to submit to Razorpay Hosted Checkout
    const form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute(
      "action",
      "https://api.razorpay.com/v1/checkout/embedded"
    );
    // form.setAttribute("target", "_blank"); 

    //Define form fields dynamically
    const fields = [
      { name: "key_id", value: `${process.env.REACT_APP_RAZORPAY_ID}` }, // Razorpay key ID
      { name: "order_id", value: RazorpayOrderId }, // The order ID returned from backend
      { name: "amount", value: amount * 100 }, // Amount 
      { name: "currency", value: "INR" },

      { name: "name", value: "Net Gala" },
      { name: "description", value: "Please complete your payment to proceed." },
      { name: "image", value: `${process.env.REACT_APP_HOSTED_URL}/logo.png` }, // Company logo or product image

      {
        name: "callback_url",
        value: `${process.env.REACT_APP_BASE_URL}/payment/callback/`,
      },
      {
        name: "cancel_url",
        value: `${process.env.REACT_APP_BASE_URL}/payment/cancel/${RazorpayOrderId}`,
      },
      { name: "prefill[name]", value: formData.FirstName }, // Optional pre-fill for name
      { name: "prefill[contact]", value: formData.Phone }, // Optional pre-fill for contact
      { name: "prefill[email]", value: formData.Email }, // Optional pre-fill for Email
    ];

    //Append fields to the form
    fields.forEach(({ name, value }) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", name);
      input.setAttribute("value", value);
      form.appendChild(input);
    });

    // Append form to the body and submit it automatically
    document.body.appendChild(form);
    form.submit();
  };



  // Format dates when eventData is received
  useEffect(() => {
    if (eventData?.EventSchedule) {
      const startDate = format(new Date(eventData.EventSchedule.StartTimeStamp), "do MMMM");
      const endDate = format(new Date(eventData.EventSchedule.EndTimeStamp), "do MMMM");
      const registrationStartDate = format(new Date(eventData.EventSchedule.RegistrationStartTimeStamp), "dd MMM, EEEE yyyy");
      const registrationEndDate = format(new Date(eventData.EventSchedule.RegistrationEndTimeStamp), "dd MMM, EEEE yyyy");
      const StartTimeStamp = format(new Date(eventData.EventSchedule.StartTimeStamp), "h:mm a");
      const EndTimeStamp = format(new Date(eventData.EventSchedule.EndTimeStamp), "h:mm a");

      setFormattedDates({
        startDate,
        endDate,
        registrationStartDate,
        registrationEndDate,
        StartTimeStamp,
        EndTimeStamp,
      });
    }
  }, [eventData]);

  const handleCloseClick = () => {
    setShowLeaveModal(true);
  };

  const handleStayClick = () => {
    setShowLeaveModal(false);
  };

  const handleLeaveClick = () => {
    setShowLeaveModal(false);
    closeModal();
  };

  //prevents scrolling of page when modal opened and resumes scrolling when modal closed
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => document.body.style.overflow = 'unset';
  }, []);



  if (!isOpen) return null;

  return (
    <div id="blackOverView-checkout" className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 object-cover">
      {!showLeaveModal ? (
        <div id="main-container-checkout" className="bg-[var(--white)] md:rounded-3xl w-full h-full md:h-auto md:w-[80vw]  md:max-h-[95vh] md:mx-4 flex flex-col md:flex-row justify-between relative ">
          {/* Left Side: Registration Form */}
          <div id="left-container-checkout" className="w-full md:w-[58%] border-r  md:pr-8 p-4  md:max-h-[70vh] overflow-hidden md:p-14">
            <div id="leftSide-top-heading-checkout" className="flex justify-between items-center ">
              <h2 className="text-[1.75rem] md:text-3xl lg:text-[2rem] font-medium font-ClashGrotesk text-[var(--dark-blue)]">
                Checkout
              </h2>

              {/* close buttone here */}
              <button
                className="text-gray-500 z-20 absolute top-3 right-3 md:top-3 md:right-4 md:text-xl"
                onClick={handleCloseClick}
              >
                &#x2715;
              </button>
            </div>

            <div id="leftSide-top-sub-heading-checkout" className="mt-3  flex flex-col  h-[50vh] overflow-hidden">
              <span className="mb-4 flex justify-between">
                <p className="subHeading-text text-base md:text-lg lg:text-xl font-semibold font-manrope text-[var(--dark-blue)] mb-2">Add registration details for all players</p>
                <p className="font-manrope text-xs mt-1"><span className="text-red-600">*</span> Required</p>
              </span>

              {/* Scrollable Form */}
              <div className="flex flex-col justify-center  overflow-hidden ">
                <hr className="text-[var(--dull-white)] border-[1.04px] md:mb-1 hidden md:block" />
                <div id="scroll-container" className="scroll-container max-h-[32vh] md:max-h-[60vh] md:pr-2 font-manrope  justify-center md:pb-4 overflow-y-auto">
                  <form className="flex flex-col px-4 md:px-0 gap-3">



                    <h1 className="text-base md:text-xs font-semibold font-manrope text-[var(--gray-brown)] md:ml-10 pt-2 hidden md:block">
                      Organizer Contact Details:
                    </h1>{" "}

                    {console.log("errors", errors)}
                    {console.log('team errors:', teamErrors)}

                    {Array.from({ length: count }, (_, i) => (
                      <React.Fragment key={i}>
                        {i === 0 ? (
                          <>
                            <div id="form-mainPlayer-part-checkout" className="flex flex-col md:flex-row gap-4 w-full text-[var(--dark-blue)] font-manrope font-semibold border border-solid rounded-3xl md:border-none p-8 md:p-0 ">
                              <p className="font-semibold font-manrope text-[var(--dark-blue)] md:hidden">Your Details</p>
                              <p className="text-[var(--dark-blue)]  border-[var(--dark-blue)] border-solid border-2 rounded-full w-6 h-6  p-2 md:flex justify-center items-center mt-2 hidden ">{i + 1}</p>
                              <div className="flex flex-col w-full gap-4">
                                <div className="flex flex-col md:flex-row gap-4  ">
                                  <input required
                                    type="text"
                                    placeholder="First Name*"
                                    name="FirstName"
                                    value={formData.FirstName}
                                    onChange={handleChange}
                                    className={` border py-4 p-2 rounded-lg w-full  bg-[var(--white)] md:text-xs ${errors.FirstName ? 'border-red-500' : 'border-[var(--input-border-gray)]'}`}
                                  />
                                  <input required
                                    type="text"
                                    placeholder="Last Name*"
                                    name="LastName"
                                    value={formData.LastName}
                                    onChange={handleChange}
                                    className={`border py-4 p-2 rounded-lg w-full  bg-[var(--white)] md:text-xs ${errors.LastName ? 'border-red-500' : 'border-[var(--input-border-gray)]'} `}
                                  />
                                </div>
                                <div className="flex flex-col md:flex-row gap-4">
                                  <input
                                    type="Email"
                                    placeholder="Email*"
                                    name="Email"
                                    value={formData.Email}
                                    onChange={handleChange}
                                    className={`border py-4  p-2 rounded-lg w-full  bg-[var(--white)] text-[var(--dark-blue)] md:text-xs ${errors.Email ? 'border-red-500' : 'border-[var(--input-border-gray)]'}`}
                                  />
                                  <select id="custom-select-1-checkout" name="Gender" value={formData.Gender} onChange={handleChange} className={`select-button custom-select select md:py-4  md:p-2 h-[3.2rem]  w-full  bg-white max-w-xs  text-[var(--dark-blue)] bg-[(--white)] font-semibold md:text-xs ${errors.Gender ? 'border-red-500' : 'border-[var(--input-border-gray)]'} `} required>
                                    <option disabled selected hidden value="" className="text-[var(--gray-brown)] font-ClashGrotesk font-medium ">Gender</option>
                                    <option value="Man" >Man</option>
                                    <option value="Woman" >Woman</option>
                                    <option value="Non-Binary">Non-Binary</option>
                                  </select>
                                  <input
                                    type="tel"
                                    placeholder="Mobile*"
                                    name="Phone"
                                    value={formData.Phone}
                                    onChange={handleChange}
                                    className={`border  py-4 p-2 rounded-lg w-full  bg-[var(--white)] text-[var(--dark-blue)] md:text-xs ${teamErrors.teamMembers?.[i].Phone ? 'border-red-500' : 'border-[var(--input-border-gray)]'} `}
                                  />

                                </div>
                              </div>


                            </div>




                            <br />
                            <hr className="text-[var(--dull-white)] md:-mt-6 border-[1.04px] mb-0 hidden md:block" />


                          </>

                        ) : (
                          <>
                            {i === 1 ? (
                              <p className="mt-2 md:ml-10 md:text-xs font-manrope font-semibold text-[var(--gray-brown)] hidden md:block">
                                Well done! Now, fill in the details for the rest
                                of your team members
                              </p>
                            ) : (
                              <p>

                              </p>
                            )}

                            <div id="form-loop-part-checkout" className="flex flex-col md:flex-row gap-4 w-full md:items-start font-manrope  text-[var(--dark-blue)] mb-2 font-semibold border border-solid rounded-3xl md:border-none p-8 md:p-0  ">
                              <span className="flex items-center gap-2 md:mt-2">
                                <p className="text-[var(--dark-blue)]  border-[var(--dark-blue)] border-solid border-2 rounded-full w-5 h-5 md:w-6 md:h-6  p-2 flex justify-center items-center mt-1 text-sm md:text-base ">{i + 1}</p>

                                <p className="font-semibold font-manrope text-[var(--dark-blue)] md:hidden pt-1">Team Member {i}</p>

                              </span>

                              <div className="flex flex-col w-full gap-4">
                                <div className="flex flex-col md:flex-row gap-4 w-full ">
                                  <input
                                    type="text"
                                    placeholder="First Name*"
                                    name="first_name"
                                    value={teamMembers[i].FirstName}
                                    onChange={(e) => handleTeamInputChange(i, 'FirstName', e.target.value)}
                                    required
                                    className={`border py-4 p-2 rounded-lg w-full bg-[var(--white)] md:text-xs ${teamErrors.teamMembers?.[i].FirstName ? "border-red-500" : 'border-[var(--input-border-gray)]'}`}
                                  />
                                  <input
                                    type="text"
                                    placeholder="Last Name*"
                                    name="last_name"
                                    value={teamMembers[i].LastName}
                                    onChange={(e) => handleTeamInputChange(i, 'LastName', e.target.value)}
                                    required
                                    className={`border py-4 p-2 rounded-lg w-full bg-[var(--white)] md:text-xs ${teamErrors.teamMembers?.[i].LastName ? "border-red-500" : "border-[var(--input-border-gray)]"}`}
                                  />
                                </div>
                                <div className="flex flex-col md:flex-row gap-4">

                                  <select name="Gender"
                                    id="custom-select-2-checkout"
                                    value={teamMembers[i].Gender}
                                    onChange={(e) => handleTeamInputChange(i, 'Gender', e.target.value)}
                                    required
                                    className={`select-button select md:py-4 p-2 h-[3.2rem]   w-full bg-white  text-[var(--dark-blue)] bg-[var(--white)] font-semibold md:text-xs ${teamErrors.teamMembers?.[i].Gender ? "border-red-500" : "border-[var(--input-border-gray)] "}`} >
                                    <option disabled selected hidden value="" className="text-[var(--gray-brown)] font-ClashGrotesk font-medium ">Gender</option>
                                    <option value="Man" >Man</option>
                                    <option value="Woman" >Woman</option>
                                    <option value="Non-Binary">Non-Binary</option>
                                  </select>
                                  <input
                                    type="tel"
                                    placeholder="Mobile*"
                                    name="mobile"
                                    value={teamMembers[i].Phone}
                                    onChange={(e) => handleTeamInputChange(i, 'Phone', e.target.value)}
                                    required
                                    className={`border py-4 p-2 rounded-lg w-full bg-[var(--white)] text-[var(--dark-blue)] md:text-xs ${teamErrors.teamMembers?.[i].Phone ? "border-red-500" : "border-[var(--input-border-gray)]"}`}
                                  />

                                </div>
                              </div>
                            </div>
                          </>
                        )}


                      </React.Fragment>
                    ))}

                  </form>

                </div>
              </div>
            </div>
          </div>

          {/* Right Side: Order Summary */}
          <div id="right-part-checkout" className="w-[90%] mx-auto md:mx-0 flex flex-col gap-2 md:w-[42%] bg-[var(--background-light-gray)] py-2 px-6 md:p-10 mb-8 md:mb-0 md:mt-0 md:max-h-[30%]  md:rounded-r-3xl rounded-3xl md:rounded-none  md:rounded-bl-none relative ">
            <div className="flex justify-between">
              <h3 id="right-part-checkout-heading" className="font-semibold font-manrope text-base text-[var(--dark-blue)] hidden md:block">
                Order Summary
              </h3>
            </div>

            {/* Event Title and description for right side only for web view*/}
            <div className="mt-4 font-manrope font-semibold hidden md:block">
              <p className="text-[var(--dark-blue)] font-bold text-xl mb-1">
                {eventData.EventTitle} x {count}
              </p>
              <hr className="text-[var(--input-border-gray)]  border-[1.5px] my-4 md:my-2 " />
              <p className="text-[var(--gray-brown)]  mt-2 flex items-center space-x-2">
                <i className="far fa-calendar-alt"></i>
                <span className="flex gap-2 md:text-base">
                  <img src={calendarVector} alt="calendar" />
                  {formattedDates.startDate}
                  {formattedDates.endDate > formattedDates.startDate ? ` - ${formattedDates.endDate}` : ''}
                </span>
              </p>
              <hr className="text-[var(--input-border-gray)]  border-[1.5px] my-2 hidden md:block" />
              <p className="text-[var(--gray-brown)] mt-2 flex items-center space-x-2">
                <i className="far fa-clock"></i>
                <span className="flex gap-2 md:text-base">
                  <img src={timeVector} alt="clock" />
                  {formattedDates.StartTimeStamp} -{" "}
                  {formattedDates.EndTimeStamp}
                </span>
              </p>
              <hr className="text-[var(--input-border-gray)]  border-[1.5px] my-2 hidden md:block" />
              <p className="text-[var(--gray-brown)] mt-2 flex items-center space-x-2">
                <i className="fas fa-map-marker-alt"></i>
                <span className="flex gap-2 md:text-base">
                  <img src={locationVector} alt="location" />
                  {eventData.venue.VenueName}{", "}
                  {eventData.venue.VenueAddress.city}
                </span>
              </p>
              <hr className="text-[var(--input-border-gray)] border-[1.5px] my-2 mt-4 md:mt-0" />
            </div>

            {/* Event Title and description as accordian for mobile device */}
            <div id="accordian-container-checkout" className="md:hidden mt-4 font-manrope font-semibold">
              <div className="collapse collapse-arrow">
                <input type="checkbox" name="my-accordion-1" defaultChecked />
                {/* Title here */}
                <div id="collapse-title-accordian-checkout" className="collapse-title text-xl font-medium">
                  <p id="event-title-accordian" className="text-[var(--dark-blue)] font-bold text-xl mb-1 -ml-3">
                    {eventData.EventTitle} x {count}
                  </p>
                  <hr className="text-[var(--input-border-gray)]  border-[1.5px] my-2 md:my-2 -ml-3" />
                </div>
                <div id="collapse-content-accordian-checkout" className="collapse-content p-0">
                  <p className="text-[var(--gray-brown)]  mt-2 flex items-center space-x-2">
                    <i className="far fa-calendar-alt"></i>
                    <span id="calendar-accordian" className="flex gap-2 md:text-base">
                      <img id="calendar-img-accordian" src={calendarVector} alt="calendar" />
                      {formattedDates.startDate}
                    </span>
                  </p>

                  <p className="text-[var(--gray-brown)] mt-2 flex items-center space-x-2">
                    <i className="far fa-clock"></i>
                    <span id="time-accordian" className="flex gap-2 md:text-base">
                      <img id="time-img-accordian" src={timeVector} alt="clock" />
                      {formattedDates.StartTimeStamp} -{" "}
                      {formattedDates.EndTimeStamp}
                    </span>
                  </p>

                  <p className="text-[var(--gray-brown)] mt-2 flex items-center space-x-2">
                    <i className="fas fa-map-marker-alt"></i>
                    <span id="location-accordian" className="flex gap-2 md:text-base">
                      <img id="location-img-accordian" src={locationVector} alt="location" />
                      {eventData.venue.VenueAddress.city},{" "}
                      {eventData.venue.VenueAddress.state},{" "}
                      {eventData.venue.VenueAddress.country},{" "}
                      {eventData.venue.VenueAddress.pincode}
                    </span>
                  </p>
                  <hr className="text-[var(--input-border-gray)] border-[1.5px] mt-4  md:mt-0 md:hidden" />
                </div>

              </div>
            </div>



            {/* Checkbox for subscription and terms and conditions */}
            <form id="agree-checkboxForm-checkout" className="overflow-hidden md:mt-2 mb-2 md:mb-0">
              <div id="subscription-checkbox-mobile" className="md:mt-4 font-manrope font-semibold text-sm text-[var(--dark-blue)]">
                <div className="checkbox-container md:flex md:items-center space-x-2 flex items-start ">
                  <input className="custom-checkbox" type="checkbox" defaultChecked id="newsletter" name="subscribeUpdates" checked={formData.subscribeUpdates} onChange={handleChange} />
                  <label className="flex shrink-0 mt-[0.15rem] md:mt-0" htmlFor="newsletter"></label>
                  <span>Keep me updated on more events and news from Net Gala</span>
                </div>
                <div className="checkbox-container flex items-start md:items-center space-x-2 mt-2">
                  <input className={'custom-checkbox'} type="checkbox" defaultChecked id="terms" name="agreeTerms" checked={formData.agreeTerms} onChange={handleChange} />
                  <label className="flex shrink-0" htmlFor="terms" style={errors.agreeTerms ? { borderColor: 'rgba(239, 68, 68, 1)' } : {}}>
                  </label>
                  <div>
                    <span>
                      I agree to the{" "}
                      <span className="underline">
                        <Link to="/terms" target="_blank" className="hover:text-[var(--reddish-brown)]">
                          Net Gala Terms of Service
                        </Link>
                      </span>
                    </span>
                  </div>
                </div>

              </div>
            </form>

            <div className="md:mt-auto">
              {/* Event Price here */}
              <div id="checkout-event-price" className="md:mt-4 md:pt-4 mb-4 md:mb-0">
                <p className="font-normal font-ClashGrotesk text-2xl text-[var(--dark-blue)]">
                  Total <span className="font-medium">₹{eventData.Price * count}</span>
                </p>
              </div>


              {/*  CTA button here */}
              <button
                onClick={handleSubmit}
                id="proceed-to-payment-button"
                className={`w-full mb-3 md:mb-0 mx-auto  font-manrope  py-4 rounded-2xl  md:text-[1.1rem] transition-colors duration-300 ease-in-out 
                      ${eventData.isFull ?
                    (!isFormValid ?
                      'bg-[var(--netGala-green-disabled-btn)] text-[var(--netGala-green-disabled-btn-txt)] font-bold cursor-default' :
                      'bg-[var(--button-green)] text-[var(--green-button-text-black)] font-bold'
                    ) :
                    (isFormValid ?
                      'bg-[var(--purple-blue)] text-[var(--white)] font-semibold' :
                      'bg-[var(--deep-plum-disabled-button)] text-[var(--gray-brown)] font-semibold cursor-default'
                    )}`}
              >
                {loading ? (
                  <ClipLoader color="white" size={20} />
                ) : (
                  eventData.isFull ? "Join Waitlist" : "Proceed to Payment"
                )}
              </button>
              {duplicateRegistration && (
                <div className="text-red-500 text-xs mx-auto md:text-base md:mt-1">
                  Oops! This number or email is already linked to a registration. Please try a different one.
                </div>
              )}
              {sameNumberEntered && (
                <div className="text-red-500 text-xs mx-auto md:text-base md:mt-1">
                  Oops! This number or email is already linked to a registration. Please try a different one.
                </div>
              )}
              {isMember && (
                <div className="text-red-500 text-xs mx-auto md:text-base md:mt-1">
                  You are a member! Please use mobile app to pay.
                </div>
              )}
              {checkoutError&&(
                <div className="text-red-500 text-xs mx-auto md:text-base md:mt-1">
                  Oops! Some error occured while checkout.
                </div>
              )}

            </div>

          </div>
        </div>
      ) : (
        <LeaveCheckout onStay={handleStayClick} onLeave={handleLeaveClick} />
      )}
    </div>
  );
};

export default CheckoutModal;
