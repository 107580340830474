
import { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import "./MembershipPackageModal.css"
import rightArrow from "../../../../Asset/Membership/MembershipPackages/Checkout/membership-right-arrow.svg"
import pipeLine from "../../../../Asset/Membership/MembershipPackages/Checkout/line.svg"

const MembershipPackageModal = ({ onClose, toggleLoader, modalData }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        ext: "",
        gender: "",
        org_id: "",
        role: "General", // Default role to "General"
        agreeTerms: false,
        subscribeUpdates: false
    });

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});


    //handle checkbox change
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const validateFields = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = true;
        if (!formData.lastName) newErrors.lastName = true;
        if (!validateEmail(formData.email)) newErrors.email = true;
        if (!validateMobile(formData.mobile)) newErrors.mobile = true;
        if (!formData.gender) newErrors.gender = true;
        if (!formData.agreeTerms) newErrors.agreeTerms = true;

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Check if all input fields are filled and checkbox is checked
    const checkFormValidity =
        formData.firstName.trim() !== '' &&
        formData.lastName.trim() !== '' &&
        formData.email.trim() !== '' &&
        formData.mobile.trim() !== '' &&
        formData.gender !== '' &&
        formData.agreeTerms;


    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateMobile = (mobile) => {
        const mobileRegex = /^[0-9]{10}$/;
        return mobileRegex.test(mobile);
    };

    const registerClient = async (e) => {
        e.preventDefault();

        if (!validateFields()) return;

        setLoading(true); // Show loader

        try {
            // Check if the user already exists using the /users/profile/{MobileNumber} endpoint
            let id; // Initialize the variable to store user ID
            try {
                const profileResponse = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/users/profile/${formData.mobile}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            accept: "application/json",
                        },
                    }
                );
                console.log("User already exists:", profileResponse.data);
        
                // If the user exists, get the user ID
                const { UserId } = profileResponse.data; // Destructure UserId from the response
                id = UserId; // Assign the user ID to the variable
                console.log(`From users/profile/{Mobile number response}: ${id}`);
            } catch (error) {
                if (error.response && (error.response.status === 500 || error.response.status === 404)) {
                    console.log("User does not exist. Proceeding with signup.");
        
                    // User does not exist, proceed with the signup process
                    const signupResponse = await axios.post(
                        `${process.env.REACT_APP_BASE_URL}/signup/`,
                        {
                            email: formData.email,
                            mobile: formData.mobile,
                            first_name: formData.firstName,
                            last_name: formData.lastName,
                            org_id: formData.org_id || "", // Ensure org_id is included or defaults to empty string
                            role: formData.role,
                            ext: formData.ext || "+91", // Optional
                            gender: formData.gender,
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                accept: "application/json",
                            },
                        }
                    );
                    console.log("Response of Signup:", signupResponse.data);
        
                    // Extract user ID from the signup response
                    id = signupResponse.data.id; // Assign the user ID directly
                    console.log(`From /signup response: ${id}`);
                } else {
                    console.error("Error during user profile check:", error);
                    throw error; // Re-throw error to exit the process
                }
            }
        
            //  Proceed with the /plans/orders/ endpoint
            const orderResponse = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/plans/orders/`,
                {
                    PlanId: modalData.PlanId,
                    primaryUserId: id, // Use the extracted user ID here
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        accept: "application/json",
                    },
                }
            );
            console.log("Response of Order:", orderResponse.data);
        
            const { SubOrderId } = orderResponse.data;
        
            //  Proceed with the /checkout/ endpoint
            const checkoutResponse = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/checkout/?sub_order_id=${SubOrderId}`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        accept: "application/json",
                    },
                }
            );
            console.log("Response of Checkout:", checkoutResponse.data);
        
            //  Extract RazorpayOrderId and amount for Razorpay payment
            const { RazorpayOrderId, amount } = checkoutResponse.data;
            console.log("RazorpayOrderId:", RazorpayOrderId);
        
            // Call Razorpay payment handling function
            handleRazorpayPayment(RazorpayOrderId, formData, amount);
        } catch (error) {
            console.error("Error during registration process:", error);
        } finally {
            setLoading(false); // Hide loader
        }
        
    };


    const handleRazorpayPayment = async (RazorpayOrderId, formData, amount) => {
        amount = amount * 100;
        //Dynamically create a form to submit to Razorpay Hosted Checkout
        const form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute(
            "action",
            "https://api.razorpay.com/v1/checkout/embedded"
        );
        form.setAttribute("target", "_blank");

        //Define form fields dynamically
        const fields = [
            { name: "key_id", value: `${process.env.REACT_APP_RAZORPAY_ID}`}, // Your Razorpay key ID
            { name: "order_id", value: RazorpayOrderId }, // The order ID returned from backend
            { name: "amount", value: amount * 100 }, // Amount in paise (multiply INR by 100)
            { name: "currency", value: "INR" },

            { name: "name", value: "Net Gala" },
            { name: "description", value: "Please complete your payment to proceed." },
            { name: "image", value: `${process.env.REACT_APP_HOSTED_URL}/logo.png` }, // Company logo or product image
            // { name: "image", value: `https://ibb.co/wM7K7Kc` }, // Company logo or product image
            {
                name: "callback_url",
                value: `${process.env.REACT_APP_BASE_URL}/payment/callback/`,
            },
            {
                name: "cancel_url",
                value: `${process.env.REACT_APP_BASE_URL}/payment/cancel`,
            },
            { name: "prefill[name]", value: formData.firstName }, // Optional pre-fill for name
            { name: "prefill[contact]", value: formData.mobile }, // Optional pre-fill for contact
            { name: "prefill[email]", value: formData.email }, // Optional pre-fill for email
        ];

        //Append fields to the form
        fields.forEach(({ name, value }) => {
            const input = document.createElement("input");
            input.setAttribute("type", "hidden");
            input.setAttribute("name", name);
            input.setAttribute("value", value);
            form.appendChild(input);
        });

        // Append form to the body and submit it automatically
        document.body.appendChild(form);
        form.submit();
        console.log(form);


    };





    return (
        <div className="membership-package-modal fixed inset-0 bg-black bg-opacity-60 flex justify-center md:items-center items-end z-50 ">
            <div className="relative bg-[var(--background-light-gray)] md:bg-[var(--white)] rounded-t-3xl md:rounded-3xl shadow-lg w-[100%] md:max-w-5xl max-h-[96vh] md:h-[60vh] md:max-h-[95vh] md:mx-4 flex flex-col md:flex-row p-3 md:p-0">
                <button onClick={onClose} className="absolute top-4 right-7 md:top-3 md:right-4 text-lg text-gray-500 hover:text-gray-700">
                    &#x2715;
                </button>
                <div className="flex flex-col lg:flex-row items-center  md:justify-between w-full">
                    {/* Left Form Section */}
                    <div className="w-full lg:w-[55%] mb-6 lg:mb-0 h-[80%]">
                        {/* <h2 className="text-2xl font-semibold mb-4"></h2> */}
                        <div className='w-[95%] md:w-[82%] mx-auto flex flex-col md:flex-row justify-between md:mb-3'>
                            <h2 className="text-[1.75rem] md:text-[2rem] leading-[3.5rem] md:mb-4 font-ClashGrotesk font-medium text-[var(--dark-blue)]">Checkout</h2>
                            <p className='font-manrope text-xs mt-auto md:mb-6 mb-2'><span className='text-red-500'>*</span>Required</p>
                        </div>
                        <div className="md:space-y-4 space-y-2 overflow-y-auto  h-[13rem] md:h-auto ">
                            <div className='flex flex-col md:flex-row md:gap-4 gap-3    md:pl-14 '>
                                <div className="flex flex-col  md:w-[15.5rem]">
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        placeholder="First Name *"
                                        className={`p-2 border md:py-3 ${errors.firstName ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[var(--white)]`}
                                    />
                                </div>
                                <div className="flex flex-col  md:w-[15.5rem]">
                                    <input
                                        type="text"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        placeholder="Last Name *"
                                        className={`p-2 border md:py-3 ${errors.lastName ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[var(--white)]`}
                                    />
                                </div>
                            </div>

                            <div className='flex md:gap-4 gap-3 flex-col md:flex-row  md:pl-14 '>
                                <div className="flex flex-col  md:w-[10.3rem]">
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="Email Address *"
                                        className={`p-2 border md:py-3 ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[var(--white)]`}
                                    />
                                </div>

                                <div className="flex flex-col  md:w-[10.3rem]">
                                    <select
                                        name="gender"
                                        value={formData.gender}
                                        onChange={handleChange}
                                        className={`select p-2 h-[3.1rem] w-full bg-white text-[var(--dark-blue)] bg-[var(--white)] font-semibold md:text-xs ${errors.gender ? "border-red-500" : "border-[var(--input-border-gray)]"
                                            }`}
                                    >
                                        <option value="" disabled hidden>
                                            Gender
                                        </option>
                                        <option value="Man">Man</option>
                                        <option value="Woman">Woman</option>
                                        <option value="Non-Binary">Non-Binary</option>
                                    </select>
                                </div>

                                <div className="flex flex-col  md:w-[10.3rem]">
                                    <input
                                        type="text"
                                        name="mobile"
                                        value={formData.mobile}
                                        onChange={handleChange}
                                        placeholder="Mobile Number *"
                                        className={`p-2 border md:py-3 ${errors.mobile ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[var(--white)]`}
                                    />
                                </div>
                            </div>


                        </div>



                    </div>

                    {/* Right Games Section */}
                    <div className="w-full  lg:w-[41%] md:h-full md:rounded-r-3xl flex flex-col justify-center items-start  md:p-4 bg-[var(--background-light-gray)] overflow-hidden md:overflow-auto">

                        <div className='h-[90%] w-full md:w-auto flex flex-col justify-between'>

                            <div className='' >
                                <div className='font-manrope font-bold text-xl text-[var(--dark-blue)] md:pl-6'>
                                    {modalData.PlanTier} Membership
                                </div>

                                {/* Checkboxes Here */}
                                <div className='w-full md:flex md:flex-col gap-3 hidden'>
                                    <div className="checkbox-container md:flex items-center md:pl-6 pt-5  ">
                                        <input type="checkbox" name="subscribeUpdates" checked={formData.subscribeUpdates} onChange={handleChange} className="custom-checkbox" id="signup-newsletter" />
                                        <label htmlFor="signup-newsletter" className="mr-2"></label>
                                        <span className='font-manrope font-semibold text-xs text-[var(--gray-brown)]'>Keep me updated on more events and news from Net Gala</span>
                                    </div>
                                    <div className="checkbox-container md:flex  items-center md:pl-6 pb-5">
                                        <input type="checkbox" name="agreeTerms" checked={formData.agreeTerms} onChange={handleChange} className={`custom-checkbox`} id="signup-terms" />
                                        <label htmlFor="signup-terms" className="mr-2" style={errors.agreeTerms ? { borderColor: 'rgba(239, 68, 68, 1)' } : {}}></label>
                                        <div className='font-manrope font-semibold text-xs text-[var(--gray-brown)]'>
                                            I agree to the{' '}
                                            <Link to="/terms">
                                                <u className="text-[var(--dark-blue)]">
                                                    Net Gala Terms of Service
                                                </u>
                                            </Link>
                                        </div>
                                    </div>
                                </div>



                                {/* Checkbox for mobile view */}
                                <div className="checkbox-container flex items-center md:pl-14 pt-5  md:hidden">
                                    <input id='mobile-keepMeUpdated' type="checkbox" name="subscribeUpdates" checked={formData.subscribeUpdates} onChange={handleChange} className="custom-checkbox mr-2" />
                                    <label htmlFor='mobile-keepMeUpdated' className='mr-2'></label>
                                    <span className='font-manrope font-semibold text-xs text-[var(--gray-brown)]'>Keep me updated on more events and news from Net Gala</span>
                                </div>

                                <div className="checkbox-container flex md:items-center md:pl-14 pb-3 pt-2 w-full md:hidden">

                                    <input id='mobile-ng-terms' type="checkbox" name="agreeTerms" checked={formData.agreeTerms} onChange={handleChange} className={`custom-checkbox mr-2 ${errors.agreeTerms ? 'border-red-500' : ''} `} />
                                    <label htmlFor='mobile-ng-terms' className='mr-2' style={errors.agreeTerms ? { borderColor: 'rgba(239, 68, 68, 1)' } : {}}>
                                    </label>
                                    <div className='font-manrope font-semibold text-xs text-[var(--gray-brown)]'>
                                        I agree to the{' '}
                                        <Link to="/terms">
                                            <u className="text-[var(--dark-blue)]">
                                                Net Gala Terms of Service
                                            </u>
                                        </Link>
                                    </div>
                                    <br />

                                </div>

                                {/*  Code Input */}
                                <div className=" mt-2 md:mt-4 flex items-center pl-8 relative">
                                    <span className='absolute left-10 text-[var(--membership-checkout-modal-gray)] font-manrope flex items-center gap-3'>
                                        <span className='text-base font-semibold'>Code</span>
                                        <span className=''>
                                            <img src={pipeLine} alt="" />
                                        </span>
                                    </span>

                                    <input
                                        type="text"

                                        className="w-[90%] pl-16 h-16 text-sm border rounded-xl bg-white border-gray-300 focus:outline-none "
                                    />
                                    <button
                                        className="absolute right-[2.4rem] px-4 h-[3.6rem] bg-[var(--button-green)] rounded-r-xl"
                                        aria-label="Apply Code"
                                    >
                                        <span>
                                            <img src={rightArrow} alt="" />
                                        </span>
                                    </button>
                                </div>

                                {/* Total Price */}
                                <div className="mt-6 pl-8 font-ClashGrotesk text-[2rem] text-[var(--dark-blue)]">
                                    <span className="font-normal">Total </span>
                                    <span className='font-medium'>₹{modalData.Price}</span>
                                </div>



                            </div>



                            {/* Register button that triggers form submission for desktop view */}
                            <div className=' w-full md:w-[90%] mt-3 md:mt-0  flex justify-center  md:mx-auto '>
                                <button type="button" onClick={registerClient} className={` w-[96%] mx-auto  py-4  rounded-lg  font-manrope font-semibold text-[1.1rem]   ${!checkFormValidity ? 'bg-[var(--deep-plum-disabled-button)] text-[var(--gray-brown)] cursor-default' : 'bg-[var(--purple-blue)] text-[var(--white)]'}`}>
                                    {loading ? (
                                        <ClipLoader color="white" size={20} />
                                    ) : (
                                        "Proceed to Payment"
                                    )}
                                </button>
                            </div>

                        </div>



                    </div>
                </div>
            </div>
        </div>
    );
};

export default MembershipPackageModal;
