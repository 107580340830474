import React, { useState, useEffect } from 'react'
import Header from '../Header/header'
import Footer from '../Footer/footer'
import EventListingTextContent from './EventListingComponents/EventListingTextContent';
import { useParams, useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import Axios from 'axios';
import MoreEventCard from '../EventsPage/EventComponents/MoreEventCard';
import FilterButton from './EventListingComponents/FilterButton';
import "./eventListing.css"
import PaymentSuccessBackgroundImage from './../../Asset/Events/paymentStatus/payment-success-banner.png'
import DownloadInvoiceButton from "./../../Asset/Events/paymentStatus/Download-invoice-button.svg"
import RetryPaymentButton from "./../../Asset/Events/paymentStatus/Retry-payment-button.svg"
import RedCrossPaymentFailure from "./../../Asset/Events/paymentStatus/red-cross-payment-failure.svg"


const EventsListing = () => {


  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allEvents, setAllEvents] = useState(null);
  const [eventCount, setEventCount] = useState(0);
  const [eventFilters, setEventFilters] =
    useState({
      "regions": [
      ],
      "sports": [
      ],
      "events": [
      ]
    })
  const [allEventFilter, setAllEventFilter] = useState(useState({
    "regions": [
    ],
    "sports": [
    ],
    "events": [
    ]
  }));

  //useStates related to payment banner
  // Use States for payment status view
  const [topBar, setTopBar] = useState(true);
  const [paymentSuccessView, setPaymentSuccessView] = useState(false);
  const [paymentFailureView, setPaymentFailureView] = useState(false);

  // Use States for payment status
  const [paymentData, setPaymentData] = useState(null); // State to store payment data
  const [storeSubOrderId, setStoreSubOrderId] = useState('');
  const [loadingStatus, setLoadingStatus] = useState(true); // State to handle loading
  const [errorStatus, setErrorStatus] = useState(null); // State to handle errors

  const [invoiceBtnLoading, setInvoiceBtnLoading] = useState(false);

  //use States for retry button
  const [loadingRetryButton, setLoadingRetryButton] = useState(false);


  let { eventId } = useParams();
  const [qParams] = useSearchParams();
  const payment_id = qParams.get('payment_id');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(`${process.env.REACT_APP_BASE_URL}/events/regions`)
        const eventFilters = response.data;


        if (eventFilters != null) {
          setAllEventFilter(addIdAndValues(eventFilters))
        }

      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    }
    fetchData();
  }, [eventId])


  useEffect(() => {
    const fetchData = async () => {
      try {

        const queryString = createQueryString(eventFilters)


        const response = await Axios.get(`${process.env.REACT_APP_BASE_URL}/events/?${queryString}`)
        const allEvents = response.data.events;
        setAllEvents(allEvents);
        setEventCount(allEvents.length);

      } catch (error) {
        setError(error.message);
        setAllEvents([]);
        setEventCount(0);
      } finally {
        setLoading(false);
      }
    }
    fetchData();

  }, [eventFilters])


  const addIdAndValues = (res) => {
    return {
      regions: res.regions.map((name, index) => ({
        id: index + 1,
        name: name
      })),
      sports: res.sports.map((name, index) => ({
        id: index + 1,
        name: name
      })),
      events: res.events.map((name, index) => ({
        id: index + 1,
        name: name
      }))
    };
  };

  const createQueryString = (filters) => {
    const queryParams = new URLSearchParams();

    const today = new Date();
    const startOfDay = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}T00:00:00`;

    queryParams.append('event_time_gteq', startOfDay);

    // Add multiple region names, sport types, and event types (without using [])
    if (filters.regions && Array.isArray(filters.regions)) {
      filters.regions.forEach(region => queryParams.append('region_name', region.name));
    }

    if (filters.sports && Array.isArray(filters.sports)) {
      filters.sports.forEach(sport => queryParams.append('sport_type', sport.name));
    }

    if (filters.events && Array.isArray(filters.events)) {
      filters.events.forEach(event => queryParams.append('event_type', event.name));
    }

    return queryParams.toString();
  }





  // Fetch Payment Status and set payment status success and failure view
  useEffect(() => {
    if (!payment_id) {
      setErrorStatus('Payment ID is missing');
      setLoadingStatus(false);
      return;
    }

    const fetchPaymentStatus = async () => {
      try {
        setLoadingStatus(true);
        const res = await Axios.get(
          `${process.env.REACT_APP_BASE_URL}/payments/status/?payment_id=${payment_id}`
        ); // Include payment_id in API request


        setPaymentData(res.data); // Store the response data
        setLoadingStatus(false);
        setStoreSubOrderId(res.data.SubOrderId)

        // Payment Status conditions
        if (res.data.TransactionStatus === 'Success') {

          setPaymentSuccessView(true);
          setPaymentFailureView(false);

        } else {

          setPaymentSuccessView(false);
          setPaymentFailureView(true);

        }

      } catch (err) {
        setErrorStatus(err.message || 'An errorStatus occurred while fetching payment status');
        setLoadingStatus(false);
      }
    };

    fetchPaymentStatus();
  }, [payment_id]);

  // Handle download invoice
  const handleDownloadInvoice = async (e) => {
    e.preventDefault(); // Prevent the default button click behavior
    setInvoiceBtnLoading(true)
    try {
      if (!payment_id) {
        console.error('Payment ID is not available');
        return;
      }

      const response = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/payments/${payment_id}/invoice`,
        {
          params: { payment_id }, // Send the stored payment_id as a query parameter
          responseType: 'blob', // Expect a file (Blob) in the response
        }
      );

      // Create a URL for the Blob and trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `NG-${payment_id}.pdf`); // Set the desired file name
      document.body.appendChild(link);
      link.click();
      link.remove(); // Clean up the link element
    } catch (error) {
      console.error('Error downloading the invoice:', error);
      // alert('Failed to download the invoice. Please try again.');

    }
    setInvoiceBtnLoading(false)
  };

console.log(storeSubOrderId)
  // Handle payment retry
  const handleRetry = async (e) => {
    e.preventDefault();

    setLoadingRetryButton(true);
    

    try {
      const checkoutResponse = await Axios.post(
        `${process.env.REACT_APP_BASE_URL}/checkout/?sub_order_id=${storeSubOrderId}`, // URL
        {}, // Data (empty object since no body is being sent)
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        } // Config (e.g., headers)
      );

      const { RazorpayOrderId, amount } = checkoutResponse.data;
      handleRazorpayPayment(RazorpayOrderId, amount);
    } catch (error) {
      console.error("Error during checkout:", error);
      // Optional: Handle specific error cases
    } finally {
      setLoadingRetryButton(false);
    }
  }


  // Handle Razorpay checkout
  const handleRazorpayPayment = async (RazorpayOrderId, amount) => {
    //Dynamically create a form to submit to Razorpay Hosted Checkout
    const form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute(
      "action",
      "https://api.razorpay.com/v1/checkout/embedded"
    );
    // form.setAttribute("target", "_blank");

    //Define form fields dynamically
    const fields = [
      { name: "key_id", value: `${process.env.REACT_APP_RAZORPAY_ID}` }, // Your Razorpay key ID
      { name: "order_id", value: RazorpayOrderId }, // The order ID returned from backend
      { name: "amount", value: amount * 100 }, // Amount in paise (multiply INR by 100)
      { name: "currency", value: "INR" },

      { name: "name", value: "Net Gala" },
      { name: "description", value: "Please complete your payment to proceed." },
      { name: "image", value: `${process.env.REACT_APP_HOSTED_URL}/logo.png` }, // Company logo or product image
      // { name: "image", value: `http:/localhost:3000/logo.png` }, // Company logo or product image
      // { name: "image", value: `https://ibb.co/wM7K7Kc` }, // Company logo or product image
      {
        name: "callback_url",
        value: `${process.env.REACT_APP_BASE_URL}/payment/callback/`,
      },
      {
        name: "cancel_url",
        value: `${process.env.REACT_APP_BASE_URL}/payment/cancel`,
      },
      // { name: "prefill[name]", value: "name" }, // Optional pre-fill for name
      // { name: "prefill[contact]", value: "" }, // Optional pre-fill for contact
      // { name: "prefill[email]", value: "email" }, // Optional pre-fill for email
    ];

    //Append fields to the form
    fields.forEach(({ name, value }) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", name);
      input.setAttribute("value", value);
      form.appendChild(input);
    });

    // Append form to the body and submit it automatically
    document.body.appendChild(form);
    form.submit();



  };



  return (
    <div id='eventListing-container'>
      <Header />

      <div className={`pt-32  w-full ${topBar ? 'block' : 'hidden'}`}>

        {/* Payment Success */}
        <div className={`w-full  h-32 px-7 items-center justify-between ${paymentSuccessView ? 'flex' : 'hidden'}`} style={{
          backgroundImage: `url(${PaymentSuccessBackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}>
          <h1 className="font-manrope text-2xl text-[var(--white)] font-[450] ">Your payment is successful!</h1>

          <button onClick={handleDownloadInvoice} className="mr-6 cursor-pointer">
            <img src={DownloadInvoiceButton} alt="invoice button" />
          </button>

        </div>

        {/* Payment Failure */}
        <div className={`w-full bg-[#FFC9C9]  h-32 px-7 items-center justify-between ${paymentFailureView ? 'flex' : 'hidden'}`}>

          <div className="flex gap-2">
            <span><img src={RedCrossPaymentFailure} alt="red cross vector" /></span>
            <span className="text-[#812121] font-manrope text-2xl font-[450]">Your transaction has failed</span>
          </div>

          <div className="mr-6">
            <button onClick={handleRetry} className=" flex justify-center">
              {loadingRetryButton ? (<ClipLoader color="white" size={20} />)
                : (<img src={RetryPaymentButton} alt="invoice button" />)}
            </button>
          </div>
        </div>

      </div>

      {/* top text part here */}
      <EventListingTextContent />


      {/* Filter button and Upcoming text here */}
      {loading ?
        <div className="w-full h-[100vh] flex justify-center items-center">
          <ClipLoader size={50} color={"#2D1066"} loading={loading} />
        </div >
        :
        <div>
          <FilterButton count={eventCount} allFilters={allEventFilter} filters={eventFilters} setFilters={setEventFilters} />

          <div id='eventListing-moreEventsCard'>
            <MoreEventCard eventData={allEvents} sliceTill={allEvents.length} showTitle={false} />
          </div>
        </div>




      }
      <Footer />
    </div>
  )
}

export default EventsListing
