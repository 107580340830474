import './App.css';
import './constant.css';
import './common.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './Homepage/homepage';
import EventsPage from './EventsPage/eventspage';
import PaymentStatus from './Components/PaymentStatus';
import PrivacyPage from './PrivacyPage/privacy'
import TermsPage from "./TermsPage/Terms";
import AboutPage from "./AboutPage/About"
import EventsListing from "./EventsListing/eventsListing"
import MembershipPage from './MembershipPage/MembershipPage';
import ScrollToTop from './Components/ScrollToTop';
import OrderPaymentCompletion from './Components/OrderPaymentCompletion';


function App() {



  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        {/* <Route path="/events/:eventId/:paymentId" element={<EventsPage />} /> */}
        <Route path="/events/:eventId/" element={<EventsPage />} />
        
        <Route path="/events-listing" element={<EventsListing />} /> 
        <Route path="/events-listing/:paymentId" element={<EventsListing />} /> 
        {/* <Route path="/events" element={<EventsPage />} /> */}
        <Route path="/payments/:paymentId" element={<PaymentStatus />} />
        <Route path='/privacy' element={<PrivacyPage />}/>
        <Route path='/terms' element={<TermsPage />}/>
        <Route path='/about' element={<AboutPage />} />
        <Route path='/membership/:paymentId' element={<MembershipPage />}/>
        <Route path='/membership' element={<MembershipPage />}/>
        <Route path='/user/suborder/:suborderId' element={<OrderPaymentCompletion />} />
      
        
      </Routes>
    </Router>
  );
}

export default App;
